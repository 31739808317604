import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const siirdeSeinad = () => (
  <Layout>
    <SEO title="Siirdeseinad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>SIIRDESEINAD</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/ruumijagajad/voldikuksed/">
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/lootsuksed/">
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
        </Link>
        <Link
          to="/ruumijagajad/siirdeseinad/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/voldikseinad/">
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/referentsid/">
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="siirdeseinad-wrapper">
        <div className="siirdeseinad-text">
          <h3>Siirdeseinad</h3>
          <p>
            Soovitava helieristuse saavutamiseks on siirdeseina elemendid
            varustatud mehhaaniliselt reguleeritavate
            teleskooptihenditega.Siirdeseinadega tagatakse helipidavus 41 – 52
            dB. Elemente võib varustada läbikäiguuksega või aknaga. Elemendid
            valmistatakse puidust alumiiniumraamile ning kaetakse vastavalt
            soovile puiduspooniga. Spoone võimalik katta peitsiga. Kõik
            kasutatavad materjalid on keskkonnasõbralikud.
          </p>

          <p>
            MATERJALI VALIKUD Siirdeseinade pinnaehituse ja materjalid võib
            valida vastavalt soovile isegi nii, et paneeli pinnad on
            vastaskülgedel erinevad.Pindade materjali- ja tehnilise valikuga
            võib reguleerida ruumi akustilisi omadusi. Õige siirdeseina tüübi
            valikuga saavutatakse vajalik helikindlus
          </p>
        </div>

        <div className="siirdeseinad-menu">
          <Link to="/ruumijagajad/siirdeseinad/helipidavus/">
            <div className="ruumijagajad-menu-item">
              <h2>Helipidavus</h2>
            </div>
          </Link>
          <Link to="/ruumijagajad/siirdeseinad/kogunemisetyybid">
            <div className="ruumijagajad-menu-item">
              <h2>Kogunemise tüübid</h2>
            </div>
          </Link>
          <Link to="/ruumijagajad/siirdeseinad/tehnilisedandmed">
            <div className="ruumijagajad-menu-item">
              <h2>Tehnilised andmed</h2>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default siirdeSeinad
